<template>
  <page title="购物车" no-back height="full" no-header>
    <div class=" bg-gray-50 flex pr-4 items-center" slot="fixed-top">
      <cube-checkbox v-model="checkedAll">
        <span class="text-sm">全选</span>
      </cube-checkbox>
      <div class="flex-1 text-xs">共<b class="text-red-400 mx-1">5</b>件商品</div>
      <btn size="sm" text-size="sm" color="red-500">清空购物车</btn>
    </div>
    <div class="h-12 pl-4 bg-gray-50 relative hairline-top  flex items-center" slot="footer">
      <div class="flex-1 flex items-center ">
        合计：<span class="text-lg font-medium text-red-500">3920.00</span>
      </div>
      <btn color="red-500"  size="xl" text-size="base"  radius="none" @click="goOrder">去结算(3)</btn>
    </div>
    <list :empty="!TestData.examines.length">
      <cube-checkbox-group v-model="checkList" @input="onChecked" class="cart-checkbox-group">
        <div class="flex "
          v-for="item in TestData.examines" 
          :key="item.id" 
        >
          <cube-checkbox :option="item.id + ''" />
          <media-item 
            :image="item.image" 
            :title="item.title"  
            img-ratio="16/15"
            img-width="90"
            note="有了它你再也不用愁了"
            class="flex-1"

          >
            <div class="flex flex-wrap mt-2">
              <tag color="green" class="mr-2">红色</tag>
              <tag color="red" class="mr-2">125G</tag>
              <tag color="blue" class="mr-2">全网通</tag>
            </div>
            <div class="flex items-center justify-between mt-2" >
              <div class="text-red-600 font-medium"><span class="text-xs">¥</span>89.9</div>
              <div class="flex items-center">
                <i class="iconfont icon-add-select"></i>
                <div class="h-6 w-10 rounded leading-6 text-sm bg-gray-200 text-center mx-2">10</div>
                <i class="iconfont icon-sami-select"></i>
              </div>
            </div>
          </media-item>
        </div>
      </cube-checkbox-group>
      
    </list>
  </page>
</template>

<script>
import {mapGetters} from 'vuex'
import { CardItem } from '@/components'
export default {
  components: {
    CardItem
  },
  computed: {
    ...mapGetters([
      'TestData'
    ])
  },
  data() {
    return {
      checkList: [],
      checkedAll: false
    }
  },
  methods: {
    onChecked(e) {
      console.log(e);
    },
    goOrder(){
      this.$router.push('/order')
    }
  },
  created() {
    
  }
}
</script>

<style lang="stylus">
.cart-checkbox-group
  .cube-checkbox
    padding-right: 0;
  .cube-checkbox-ui
    margin 0
  .cube-checkbox-wrap
    &:after 
      content: none;
  .cube-checkbox-label
    display: none;
</style>